<template>
  <Modal
    as-form
    open
    persistent
    make-content-scrollable
    :size="ModalSize.XL"
    :title="modalTitle"
    @submit="handleSocialPost"
  >
    <template #content>
      <Alert
        v-if="!props.socialData.isEditable && !props.socialData.isCustomizable"
        class="mt-8"
      >
        {{ $t('personalize-post-modal.locked-warning') }}
      </Alert>
      <PostModalContent
        class="flex-1 mb-8"
        :social-data="formData"
        :is-editable="props.socialData.isEditable"
        :is-customizable="props.socialData.isCustomizable"
        :quadrants="props.socialData.quadrants"
        disable-social-select
        @update:social-data="formData = $event"
      />
    </template>
    <template #actions="{ closeModal }">
      <div class="py-5 px-4 sm:px-12 border-t flex justify-between">
        <Btn :size="BtnSize.LG" @click="handleClose(closeModal)">
          {{ $t('common.cancel') }}
        </Btn>
        <div class="flex">
          <Btn
            v-if="props.socialData.isCustomizable"
            class="mr-2"
            :color="BtnColor.PRIMARY"
            :variant="BtnVariant.OUTLINED"
            :size="BtnSize.LG"
            @click="handleSaveSocialPostAsDraft()"
          >
            {{ $t('personalize-post-modal.save-as-draft') }}
          </Btn>
          <Btn
            :color="BtnColor.PRIMARY"
            :variant="BtnVariant.FILLED"
            :size="BtnSize.LG"
            @click="validateVariablesBeforeSubmit"
          >
            {{
              formData.isScheduled
                ? $t('personalize-post-modal.schedule')
                : $t('personalize-post-modal.post-now')
            }}
          </Btn>
        </div>
      </div>
    </template>
  </Modal>

  <SaveAsDraftModal
    v-if="showSaveAsDraftModal"
    @cancel="showSaveAsDraftModal = false"
    @close="handleCloseSaveAsDraftModal"
    @submit="handleSaveSocialPostAsDraft"
  />
</template>

<script setup lang="ts">
import { Btn, BtnColor, BtnSize, BtnVariant } from '~/components/btn';
import {
  createStorageFilePath,
  ref,
  useFirebase,
  useSocialRedirectUrl,
} from '#imports';
import {
  apiEditSocialPost,
  apiPostAgainSocialPost,
  SocialPostDto,
  UpdateSocialPostBodyDto,
} from '~/api/socials';
import { useLoader } from '~/composables/use-loader';
import { PostModalContent } from '~/pages/my-posts/_components/post-modal-content';
import { format } from 'date-fns';
import { Alert } from '~/components/alert';
import {
  apiCreateContentSocialDraft,
  apiCreateSocialShareUrl,
  apiCreateSocialShareUrlFromDraft,
  apiUpdateContentSocialDraft,
  ContentDto,
  ContentSocialDraftDto,
  CreateContentSocialDraftBodyDto,
  CreateSocialShareUrlBodyDto,
} from '~/api/contents';
import { SocialPostStatus } from '~/pages/my-posts/my-posts.constants';
import {
  getDownloadURL,
  ref as getStorageRef,
  uploadString,
} from 'firebase/storage';
import { create as createContentDisposition } from 'content-disposition-header';
import { useCustomDescription } from '~/composables/use-custom-description';
import { useVariablesModalStore } from '~/store/variables-modal';
import { ContentSocialDto, HubDto } from '~/api/hubs';
import omit from 'lodash/omit';
import pick from 'lodash/pick';
import differenceBy from 'lodash/differenceBy';
import isEmpty from 'lodash/isEmpty';
import { SaveAsDraftModal } from '~/pages/my-posts/_components/edit-post-modal/_components/save-as-draft-modal';
import { Modal, ModalSize } from '~/components/modals/modal';
import { AccountType } from '~/api/accounts';
import { createContentLaunchMetric, LaunchMetricType } from '~/api/metrics';
import { storeToRefs } from 'pinia';
import { useAccountStore } from '~/store/account';
import { apiCreateContentFile } from '~/api/files';
import { PostModalContentForm } from '~/pages/my-posts/_components/post-modal-content/post-modal-content.types';

type Props = {
  socialData: SocialPostDto & ContentSocialDto;
  hub?: HubDto;
  content?: ContentDto;
  contentData?: { hubSlug: string; contentSlug: string; schedule: boolean };
  socialDraft?: ContentSocialDraftDto;
};

const props = defineProps<Props>();

const emit = defineEmits<{
  close: [];
  submit: [];
  submitDraft: [];
}>();

const { t } = useI18n();

const { currentAccount } = storeToRefs(useAccountStore());

const { setSubmitCallback } = useVariablesModalStore();
const { validateVariables, getCustomDescription, activateVariablesModal } =
  useCustomDescription(props.socialData.description || '');

const modalTitle = computed(() =>
  props.contentData
    ? props.contentData.schedule
      ? t('edit-post-modal.title')
      : t('personalize-post-modal.title')
    : t('edit-post-modal.title'),
);

const parsedLogos = computed(() => {
  return props.socialDraft?.logos.map((logo) => {
    return {
      ...omit(logo, ['accountLogo', 'id']),
      accountLogoId: logo.accountLogo.id,
    };
  });
});

const formData = ref<PostModalContentForm>({
  socialFiles: props.socialData.socialFiles,
  type: props.socialData.type,
  description: props.socialData.description as string,
  postPlannedAt: props.socialData.postPlannedAt || null,
  isScheduled:
    Boolean(props.socialData.postPlannedAt) ||
    Boolean(props.contentData?.schedule),
  date: props.socialData.postPlannedAt
    ? format(new Date(props.socialData.postPlannedAt as string), 'MM/dd/yy')
    : '',
  time: props.socialData.postPlannedAt
    ? format(new Date(props.socialData.postPlannedAt as string), 'hh:mm aa')
    : '',
  fromContent:
    Boolean(props.contentData) || Boolean(props.socialData.contentTitle),
  descriptionErrorState: {},
  logo: parsedLogos.value ? parsedLogos.value[0] : [],
  imageData: null,
});

const wasSubmited = ref<boolean>(false);

const showSaveAsDraftModal = ref<boolean>(false);
const isDraftDataChanged = computed(
  () =>
    !isEmpty(formData.value.logo) ||
    props.socialData.description !== formData.value.description,
);

const handleSaveSocialPostAsDraft = async (isPublishing?: boolean) => {
  const socialBody: CreateContentSocialDraftBodyDto = {
    contentSocialId: props.socialData.id,
    finishedAt: null,
    description: formData.value.description,
    logos: [
      {
        ...formData.value.logo,
      },
    ],
  };

  if (!socialBody.logos[0]?.accountLogoId) {
    socialBody.logos = [];
  }

  if (isPublishing) {
    socialBody.finishedAt = new Date().toISOString();
  }

  await apiCreateContentSocialDraft(socialBody);

  emit('submitDraft');

  if (isPublishing) {
    return;
  }

  emit('close');
};

const handleDraft = async () => {
  if (!props.socialDraft?.finishedAt && props.socialDraft) {
    await apiUpdateContentSocialDraft(props.socialDraft.id, {
      finishedAt: new Date().toISOString(),
    });
  }

  if (props.socialData.isCustomizable && !props.socialDraft) {
    await handleSaveSocialPostAsDraft(true);
  }
};

const { firebaseStorage } = useFirebase();

const saveRenderedImage = async (dataUrl: string) => {
  const fileName = `${crypto.randomUUID()}.jpeg`;

  const storageRef = getStorageRef(
    firebaseStorage,
    createStorageFilePath(fileName),
  );

  const metadata = {
    contentType: 'image/jpeg',
    contentDisposition: createContentDisposition(fileName),
  };

  const snapshot = await uploadString(
    storageRef,
    dataUrl.slice(23),
    'base64',
    metadata,
  );

  return await getDownloadURL(snapshot.ref);
};

const getSocialShareUrl = async () => {
  let renderedFile = null;

  if (formData.value.imageData) {
    const renderedImageUrl = await saveRenderedImage(formData.value.imageData);
    renderedFile = await apiCreateContentFile(renderedImageUrl);
  }

  const areCaptionsUpdated = !isEmpty(
    differenceBy(
      props.socialData.socialFiles,
      formData.value.socialFiles,
      'caption',
    ),
  );

  const socialShareUrlBody: CreateSocialShareUrlBodyDto = {
    contentSocialId: props.socialData.id,
    description: formData.value.description,
    ...(formData.value.postPlannedAt && {
      postPlannedAt: formData.value.postPlannedAt,
    }),
    ...(renderedFile && {
      socialFiles: [{ fileId: renderedFile.id, caption: null }],
    }),
    ...(areCaptionsUpdated && {
      socialFiles: formData.value.socialFiles.map((socialFile) => ({
        fileId: socialFile.file.id,
        caption: socialFile.caption,
      })),
    }),
  };

  if (props.socialDraft || !props.contentData?.hubSlug) {
    const { shareUrl } =
      await apiCreateSocialShareUrlFromDraft(socialShareUrlBody);

    return shareUrl;
  }

  if (props.contentData) {
    const { shareUrl } = await apiCreateSocialShareUrl(
      props.contentData.hubSlug,
      props.contentData.contentSlug,
      socialShareUrlBody,
    );

    if (
      props.hub?.isLaunchEnabled &&
      currentAccount.value?.type === AccountType.STANDARD &&
      props.content &&
      !props.content.launchData.completedAt
    ) {
      await createContentLaunchMetric(
        props.hub.slug,
        props.content.slug,
        LaunchMetricType.COMPLETED,
      );
      // eslint-disable-next-line vue/no-mutating-props
      props.content.launchData.completedAt = new Date();
    }

    return shareUrl;
  }
};

const isDescriptionValid = () => {
  const shouldValidateDescription =
    formData.value.descriptionErrorState && !props.socialData.isEditable;

  if (!shouldValidateDescription) {
    return true;
  }

  return validateVariables(formData.value.descriptionErrorState);
};

const setSocialPostDescription = (
  submitCallback: () => Promise<void> | void,
) => {
  setSubmitCallback(submitCallback);
  activateVariablesModal(formData.value.descriptionErrorState);
};

const setSocialPostPlanDate = () => {
  if (formData.value.date && formData.value.time) {
    const fullDate = new Date(`${formData.value.date} ${formData.value.time}`);
    formData.value.postPlannedAt = fullDate.toISOString();
  } else {
    formData.value.postPlannedAt = null;
  }
};

const schedulePost = async (
  socialBody: UpdateSocialPostBodyDto,
): Promise<void> => {
  if (!props.socialData.isEditable) {
    formData.value.description = getCustomDescription();
  }

  await apiEditSocialPost(props.socialData.id, {
    ...socialBody,
    type: undefined,
    socialFiles: formData.value.socialFiles.map((socialFile) => ({
      fileId: socialFile.file.id,
      caption: socialFile.caption,
    })),
  });
  emit('close');
  emit('submit');
};

const retryFailedSocialPost = async (
  socialBody: Record<string, any>,
): Promise<void> => {
  const { shareUrl } = await apiPostAgainSocialPost(props.socialData.id, {
    ...pick(socialBody, ['description', 'postPlannedAt']),
    ...(props.socialData.isEditable && {
      socialFiles: formData.value.socialFiles.map((socialFile) => ({
        fileId: socialFile.file.id,
        caption: socialFile.caption,
      })),
    }),
  });
  const { updateSocialRedirectUrl } = useSocialRedirectUrl();

  updateSocialRedirectUrl();
  globalThis.location.href = shareUrl;
};

const handleSocialShareRedirect = async () => {
  if (!props.socialData.isEditable) {
    formData.value.description = getCustomDescription();
  }

  const shareUrl = await getSocialShareUrl();

  await handleDraft();

  const { updateSocialRedirectUrl } = useSocialRedirectUrl();

  updateSocialRedirectUrl();

  if (shareUrl) {
    globalThis.location.href = shareUrl;
  }
};

const handleSocialPost = async () => {
  setSocialPostPlanDate();
  await useLoader({
    action: async () => {
      if (props.contentData || props.socialDraft) {
        return await handleSocialShareRedirect();
      }

      const socialBody = {
        type: formData.value.type,
        postPlannedAt: formData.value.postPlannedAt,
        description: formData.value.description,
      };

      if (props.socialData.isCustomizable && !props.socialDraft) {
        await handleSaveSocialPostAsDraft(true);
      }

      if (props.socialData.status === SocialPostStatus.SCHEDULED) {
        return await schedulePost(socialBody);
      }

      if (props.socialData.status === SocialPostStatus.FAILED) {
        return await retryFailedSocialPost(socialBody);
      }
    },
  });
};

const handleClose = (closeModal: () => void) => {
  if (
    isDraftDataChanged.value &&
    !wasSubmited.value &&
    !props.socialDraft &&
    props.socialData.isCustomizable
  ) {
    showSaveAsDraftModal.value = true;
    return;
  }

  closeModal();
  emit('close');
};

const handleCloseSaveAsDraftModal = () => {
  showSaveAsDraftModal.value = false;
  emit('close');
};
const validateVariablesBeforeSubmit = (e: MouseEvent) => {
  if (!(e.target instanceof HTMLButtonElement))
    throw new Error('This callback should be bound to a submit button');
  if (isDescriptionValid()) {
    wasSubmited.value = true;
    e.target.form?.dispatchEvent(new Event('submit'));
  } else {
    setSocialPostDescription(() => validateVariablesBeforeSubmit(e));
  }
};
</script>
